<template>
  <section class="passes icon-app1" v-if="passes && passes.length">
    <ul class="pass-list" v-if="assignmentPasses.length">
      <li class="small-pass" v-for="pass in assignmentPasses" :key="pass.id">
        <button class="invisible-button" @click="displayPass(pass.id)">
          &nbsp;
        </button>
        <div class="info-wrapper">
          <div class="icon resource-assignment">
            <icon icon="#cx-app1-favourite-default" />
          </div>
          <ul class="info" v-if="pass.full_path && pass.full_path.length">
            <li class="name">{{ pass.full_path[0] }}</li>
            <li class="location" v-if="pass.full_path.length > 1">
              <span>{{ pass.full_path.slice(1).join(", ") }}</span>
            </li>
          </ul>
          <ul
            class="time-wrapper"
            :class="{
              hidden: !pass.datetime_to,
            }"
          >
            <li class="date">
              <time :datetime="pass.datetime_from">
                {{
                  displayDate(
                    pass.datetime_from,
                    globalDateFormat || "DD MMMM YYYY"
                  )
                }}
              </time>
            </li>
            <li class="date" v-if="pass.datetime_to">
              <time :datetime="pass.datetime_to">
                {{
                  displayDate(
                    pass.datetime_to,
                    globalDateFormat || "DD MMMM YYYY"
                  )
                }}
              </time>
            </li>
          </ul>
        </div>
        <div class="qr-code-wrapper">
          <div class="qr-code">
            <img v-if="pass.qrcode" :src="pass.qrcode" alt="Qr code" />
            <icon v-else icon="#cx-app1-no-qrcode" />
          </div>
          <div class="pin-wrapper">
            <p class="pass-pin">{{ pass.pin }}</p>
            <powered-by-clebex />
          </div>
        </div>
      </li>
    </ul>
    <ul class="pass-list" v-if="passesForToday.length">
      <li class="small-pass" v-for="pass in passesForToday" :key="pass.id">
        <button class="invisible-button" @click="displayPass(pass.id)">
          &nbsp;
        </button>
        <div class="info-wrapper">
          <div
            class="icon"
            :class="{
              active: ['STARTED', 'CHECKED_IN', 'EXTENDED'].includes(
                pass.declaration_status.status
              ),
              warning: ['PRE_CHECK_IN', 'EXPIRING_WARNING'].includes(
                pass.declaration_status.status
              ),
              error: ['EXPIRING_WARNING'].includes(
                pass.declaration_status.status
              ),
            }"
          >
            <icon :icon="displayIcon(pass.declaration_status.status)" />
          </div>
          <ul class="info" v-if="pass.full_path && pass.full_path.length">
            <li class="name">{{ pass.full_path[0] }}</li>
            <li class="location" v-if="pass.full_path.length > 1">
              <span>{{ pass.full_path.slice(1).join(", ") }}</span>
            </li>
          </ul>
          <ul class="time-wrapper">
            <li class="date">
              <time :datetime="pass.datetime_from">
                {{
                  displayDate(
                    pass.datetime_from,
                    globalDateFormat || "DD MMMM YYYY"
                  )
                }}
              </time>
            </li>
            <li class="time">
              <time :datetime="pass.datetime_from_original">{{
                displayDate(
                  pass.datetime_from_original,
                  globalTimeFormat || "HH:mm"
                )
              }}</time>
              <time :datetime="pass.datetime_to">{{
                displayDate(pass.datetime_to, globalTimeFormat || "HH:mm")
              }}</time>
            </li>
          </ul>
        </div>
        <div class="qr-code-wrapper">
          <div class="qr-code">
            <img v-if="pass.qrcode" :src="pass.qrcode" alt="Qr code" />
            <icon v-else icon="#cx-app1-no-qrcode" />
          </div>
          <div class="pin-wrapper">
            <p class="pass-pin">{{ pass.pin }}</p>
            <powered-by-clebex />
          </div>
        </div>
      </li>
    </ul>
    <ul class="pass-list" v-if="passesForOtherDays.length">
      <li class="small-pass" v-for="pass in passesForOtherDays" :key="pass.id">
        <button class="invisible-button" @click="displayPass(pass.id)">
          &nbsp;
        </button>
        <div class="info-wrapper">
          <div
            class="icon"
            :class="{
              active: ['STARTED', 'CHECKED_IN', 'EXTENDED'].includes(
                pass.declaration_status.status
              ),
              warning: ['PRE_CHECK_IN', 'EXPIRING_WARNING'].includes(
                pass.declaration_status.status
              ),
              error: ['EXPIRING_WARNING'].includes(
                pass.declaration_status.status
              ),
            }"
          >
            <icon :icon="displayIcon(pass.declaration_status.status)" />
          </div>
          <ul class="info" v-if="pass.full_path && pass.full_path.length">
            <li class="name">{{ pass.full_path[0] }}</li>
            <li class="location" v-if="pass.full_path.length > 1">
              <span>{{ pass.full_path.slice(1).join(", ") }}</span>
            </li>
          </ul>
          <ul class="time-wrapper">
            <li class="date">
              <time :datetime="pass.datetime_from">
                {{
                  displayDate(
                    pass.datetime_from,
                    globalDateFormat || "DD MMMM YYYY"
                  )
                }}
              </time>
            </li>
            <li class="time">
              <time :datetime="pass.datetime_from_original">{{
                displayDate(
                  pass.datetime_from_original,
                  globalTimeFormat || "HH:mm"
                )
              }}</time>
              <time :datetime="pass.datetime_to">{{
                displayDate(pass.datetime_to, globalTimeFormat || "HH:mm")
              }}</time>
            </li>
          </ul>
        </div>
        <div class="qr-code-wrapper">
          <div class="qr-code">
            <img v-if="pass.qrcode" :src="pass.qrcode" alt="Qr code" />
            <icon v-else icon="#cx-app1-no-qrcode" />
          </div>
          <div class="pin-wrapper">
            <p class="pass-pin">{{ pass.pin }}</p>
            <powered-by-clebex />
          </div>
        </div>
      </li>
    </ul>
    <screen-modal
      v-if="show"
      class="confirm-modal"
      type="success"
      :confirm-action="startDelete"
      :confirm-button-label="
        displayLabelName('pass', 'pass', 'delete-confirm-action')
      "
      :cancel-button-label="
        displayLabelName('pass', 'pass', 'delete-cancel-action')
      "
      :show="show"
      @close="show = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("pass", "pass", "delete-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("pass", "pass", "delete-action-text") }}
        <br />
        {{ displayLabelName("pass", "pass", "delete-action-question") }}
      </p>
    </screen-modal>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import PoweredByClebex from "@/components/global/PoweredByClebex";
import { formatDate, displayDateWithTimezone } from "@/services/helpers";
import dayjs from "dayjs";

export default {
  name: "PassList",
  data() {
    return {
      show: false,
      passesForToday: [],
      passesForOtherDays: [],
      assignmentPasses: [],
    };
  },
  computed: {
    ...mapState("pass", ["selectedPass"]),
    ...mapState("timezone", ["timezones"]),
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
  },
  watch: {
    passes: {
      handler() {
        const { passes } = this;
        const passesForToday = [];
        const passesForOtherDays = [];
        const assignmentPasses = [];
        const today = formatDate(new Date().toString(), "DD MMMM YYYY");

        if (passes && passes.length) {
          passes.forEach((pass) => {
            if (pass.spacepassable_type === "RESOURCE_ASSIGNMENT") {
              assignmentPasses.push(pass);
            } else {
              if (formatDate(pass.datetime_from, "DD MMMM YYYY") === today) {
                passesForToday.push(pass);
              } else {
                passesForOtherDays.push(pass);
              }
            }
          });
        }

        this.passesForToday = passesForToday;
        this.passesForOtherDays = passesForOtherDays;
        this.assignmentPasses = assignmentPasses;
      },
      immediate: true,
    },
  },
  methods: {
    displayDate(date, format) {
      if (this.timezones && this.timezones.data) {
        return displayDateWithTimezone({
          date,
          format,
          timezones: this.timezones,
        });
      }
      return "";
    },
    formatDate(date, format) {
      return formatDate(date, format);
    },
    isActive(expireTime) {
      return dayjs().isBefore(expireTime);
    },
    selectPass(pass) {
      this.$store.commit("pass/setSelectedPass", pass, { root: true });
    },
    startDelete() {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      this.$store
        .dispatch("pass/deletePass", this.selectedPass.id, { root: true })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
          this.show = false;
        });
    },
    displayIcon(status) {
      let result;
      switch (status) {
        case "EXPIRING_WARNING":
          result = "#cx-app1-about-to-end-80x80";
          break;
        case "AUTO_RELEASED":
          result = "#cx-app1-auto-release-80x80";
          break;
        case "PRE_CHECK_IN":
          result = "#cx-app1-check-in-80x80";
          break;
        case "CHECKED_IN":
        case "STARTED":
          result = "#cx-app1-in-use-80x80";
          break;
        default:
          result = "#cx-app1-thumbs-up-80x80";
      }
      return result;
    },
  },
  props: {
    passes: {
      required: true,
    },
    displayPass: {
      required: true,
      type: Function,
    },
    mode: {
      required: true,
      type: String,
    },
  },
  components: {
    PoweredByClebex,
  },
};
</script>
<style>
.pin-wrapper {
  flex: 1;
  text-align: right;
  padding-top: 15px;
  position: relative;
}

.pin-wrapper div {
  position: absolute;
  right: 0px;
  bottom: 15px;
}

.pass-pin {
  color: #bf328d;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}
</style>
